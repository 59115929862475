@import '~styles/colors';

.socials {
	display: flex;
	align-items: center;
}
.social {
	padding: 5px;
	margin: 5px;
	cursor: pointer;
	svg {
		width: 25px;
		fill: $WHITE;
		//@media only screen and (max-width: 767px) {
		//	width: 25px;
		//}
	}
	&:hover {
		svg {
			fill: $RED;
		}
	}
}