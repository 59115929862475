@import '~styles/colors';

.scroll-to-top {
	z-index: 5;
	padding: 8px 10px;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	border: 1px solid $MERCURY;
	cursor: pointer;
	transition: all 1s ease-in-out;
	bottom: -100%;
	background-color: $WHITE;
	-webkit-tap-highlight-color: transparent;
	&--active {
		bottom: 20px;
		opacity: 1;
		visibility: visible;
	}
	svg {
		fill: $BLACK;
		width: 12px;
	}
	.text {
		font-size: 14px;
		padding: 5px;
		color: $BLACK;
	}
	&:hover {
		.text {
			color: $RED;
		}
		svg {
			fill: $RED;
		}
	}
}

@media only screen and (max-width: 767px) {
	.scroll-to-top {
		right: 50%;
		transform: translate(50%, 0);
		padding: 4px 5px;
	}
}