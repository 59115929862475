@import '~styles/colors';
@import '~styles/vars';

.label {
	font-size: 16px;
	margin-bottom: 5px;
	display: block;
	color: $TUNDORA;
}
.wrapper {
	border-radius: 3px;
	background-color: $WHITE;
	border: 1px solid $ALTO;
	width: 50%;
	line-height: 1;
	position: relative;
	&--warning {
		border: 1px solid $WARNING;
	}
	&--error {
		border: 1px solid $RED;
	}
	&--welldone {
		border: 1px solid $JAPANESE_LAUREL;
	}
	&:after {
		content: '\f107';
		font-family: $FONT_AWESOME;
		font-size: 14px;
		color: $BLACK;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		line-height: 1;
		right: 10px;
	}
	@media only screen and (max-width: 767px) {
		width: 100%;
	}
}
.select {
	padding: 10px 15px;
	border: none;
	outline: none;
	width: 100%;
	font-family: $FONT_MAIN;
	font-weight: normal;
	font-size: 16px;
	background-color: $WHITE;
	-webkit-appearance: none;
}
.error, .warning {
	padding: 5px 0;
	margin-top: 5px;
	font-size: 14px;
}
.error {
	color: $RED;
}
.warning {
	color: $WARNING;
}