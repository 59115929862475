@import '~styles/colors';
@import '~styles/vars';

.bar-button {
	line-height: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 27px;
	height: 20px;
	padding: 5px 2px;
	-webkit-tap-highlight-color: transparent;
	touch-action: manipulation;

	&::before, &::after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background-color: $WHITE;
	}

	&:hover::before, &:hover::after {
		background-color: $RED;
	}

	&-small {
	}
	&-medium {
	}
}

.text {
	display: inline-block;
	padding-left: 5px;
}

.green {
	background-color: $JAPANESE_LAUREL;
	color: $WHITE;

	&:hover {
		background-color: $JAPANESE_LAUREL_HOVER;
	}
	&:active {
		background-color: $JAPANESE_LAUREL_ACTIVE;
	}
	svg {
		stroke: $WHITE;
		fill: $WHITE;
	}
}

.red {
	background-color: $MONZA;
	color: $WHITE;

	&:hover {
		background-color: $MONZA_HOVER;
	}
	&:active {
		background-color: $MONZA_ACTIVE;
	}
	svg {
		stroke: $WHITE;
		fill: $WHITE;
	}
}

.hollow-primary {
	display: inline-flex;
	color: $BLACK;
	font-family: $FONT_MAIN_BOLD;
	border: 1px solid $ALTO;
	background-color: transparent;
	&:hover {
		background-color: darken($WHITE, 3%);
		color: $RED;
	}
}

.border-3 {
	border-radius: 3px;
}
.border-5 {
	border-radius: 5px;
}
.border-25 {
	border-radius: 25px;
}
.border-100 {
	border-radius: 100%;
}

.bold {
	font-family: $FONT_MAIN_BOLD;
}

.disabled {
	background-color: $GRAY;
	cursor: default;
	&:hover, &:active {
		background-color: $GRAY;
	}
}