@import '~styles/colors';

.switch-tab {
	display: inline-flex;
	align-items: stretch;
	background-color: $WHITE;
	padding: 2px;
	position: relative;
	border-radius: 3px;
}

.tab {
	margin-left: -2px;
	padding: 10px 25px;
	background-color: transparent;
	display: inline-flex;
	align-items: center;
	font-size: 16px;
	cursor: pointer;
	border: 2px solid $ALTO;
	position: relative;
	-webkit-tap-highlight-color: transparent;
	user-select: none;
	&:hover, &--active {
		z-index: 5;
		border: 2px solid $BLACK;
		background-color: darken($WHITE, 3%);
	}
}

.switch-body {
	margin: 20px 0;
}

.label {
	font-size: 16px;
	margin-bottom: 5px;
	display: block;
	color: $TUNDORA;
}
