@import '~styles/colors';
@import '~styles/vars';

.btn {
	line-height: 1;
	display: inline-flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	//transition: all .05s ease-in-out;
	position: relative;
	font-family: $FONT_MAIN;
	min-width: 65px;

	&-small {
		padding: 12px 20px;
		min-height: 37px;
		font-size: 14px;
	}
	&-medium {
		padding: 15px 30px;
		min-height: 43px;
		font-size: 16px;
	}
	&-loading {
		padding-left: 35px;
	}
	svg {
		position: absolute;
		top: 50%;
		left: 12%;
		transform: translate(-50%, -50%);
		width: 15px;
		stroke: $WHITE;
		//fill: $WHITE;
	}
}

.text {
	display: inline-block;
}

.green {
	background-color: $JAPANESE_LAUREL;
	color: $WHITE;

	&:hover {
		background-color: $JAPANESE_LAUREL_HOVER;
	}
	&:active {
		background-color: $JAPANESE_LAUREL_ACTIVE;
	}
	svg {
		stroke: $WHITE;
		fill: $WHITE;
	}
}

.black {
	background: $BLACK_GRADIENT;
	color: $WHITE;

	&:hover {
		background: $BLACK_GRADIENT_HOVER;
	}
	&:active {
		background: $BLACK_GRADIENT_ACTIVE;
	}
	svg {
		stroke: $WHITE;
		fill: $WHITE;
	}
}

.red {
	background-color: $MONZA;
	color: $WHITE;

	&:hover {
		background-color: $MONZA_HOVER;
	}
	&:active {
		background-color: $MONZA_ACTIVE;
	}
	svg {
		stroke: $WHITE;
		fill: $WHITE;
	}
}

.hollow-primary {
	display: inline-flex;
	color: $BLACK;
	font-family: $FONT_MAIN_BOLD;
	border: 1px solid $ALTO;
	background-color: transparent;
	&:hover {
		background-color: darken($WHITE, 3%);
		color: $RED;
	}
}

.border-3 {
	border-radius: 3px;
}
.border-5 {
	border-radius: 5px;
}
.border-25 {
	border-radius: 25px;
}
.border-100 {
	border-radius: 100%;
}

.bold {
	font-family: $FONT_MAIN_BOLD;
}

.disabled {
	background-color: $GRAY;
	cursor: default;
	&:hover, &:active {
		background-color: $GRAY;
	}
}