$BLACK: #000;
$BLACK_LIGHT: #222;
$WHITE: #fff;
$SEASHELL: #f1f1f1;
$RED: red;
$ALABASTER: #f7f7f7;
$MERCURY: #e8e8e8;
$SILVER: #bfbfbf;
$WILD_SAND: #fafafa;
$GRAY: #939393;
$ALTO: #d0d0d0;
$DOVE_GRAY: #7d7d7d;
$GALLERY: #efefef;
$TUNDORA: #444343;
$MINE_SHAFT: #3a3a3a;
$TEXT_COLOR: #272727;
$WHITE_DIMMED: rgba(255,255,255,.2);
$CARRIBEAN: #07d185;
$WARNING: #d17708;
$MODAL_BACKGROUND: rgba(0,0,0,.7);
$HOVER_GRAY: rgba(0,0,0,.1);

// BUTTONS

/* Green */
$JAPANESE_LAUREL: #1a8608;
$JAPANESE_LAUREL_HOVER: #1a9508;
$JAPANESE_LAUREL_ACTIVE: #1b7908;

/* Red */
$MONZA: #da0909;
$MONZA_HOVER: #e70b0b;
$MONZA_ACTIVE: #ce0c0c;

/* BLACK */
$BLACK_GRADIENT: linear-gradient($BLACK, $BLACK);
$BLACK_GRADIENT_HOVER: linear-gradient($TUNDORA, $BLACK_LIGHT);
$BLACK_GRADIENT_ACTIVE: linear-gradient($BLACK, $BLACK);
