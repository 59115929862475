@import '~styles/colors';
@import '~styles/vars';

.label {
	font-size: 16px;
	margin-bottom: 5px;
	display: block;
	color: $TUNDORA;
}
.wrapper {
	border-radius: 3px;
	background-color: $WHITE;
	border: 1px solid $ALTO;
	width: 100%;
	line-height: 1;
	&--warning {
		border: 1px solid $WARNING;
	}
	&--error {
		border: 1px solid $RED;
	}
	&--welldone {
		border: 1px solid $JAPANESE_LAUREL;
	}
}
.textarea {
	padding: 10px 15px;
	border: none;
	outline: none;
	width: 100%;
	font-family: $FONT_MAIN;
	font-weight: normal;
	font-size: 16px;
	min-height: 130px;
}
.error {
	padding: 5px 0;
	margin-top: 5px;
	color: $RED;
	font-size: 14px;
}