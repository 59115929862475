@import '~styles/colors';
@import '~styles/vars';

.footer {
	background: center/cover no-repeat;
	position: relative;
	&-wrapper {
		position: relative;
		z-index: 1;
		font-size: 18px;
		color: $WHITE;
	}
	&-top {
		padding: 20px 0;
	}
	&-bottom {
		padding: 30px 0;
		border-top: 1px solid $WHITE_DIMMED;
		@media only screen and (max-width: 767px) {
			padding: 15px 0;
		}
	}
}
.phone {
	display: inline-block;
	margin-left: 20px;
	color: $WHITE;
	&:hover {
		color: $RED;
	}
}
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: 0;
}
.policy-link {
	padding: 20px 0;
	@media only screen and (max-width: 767px) {
		padding: 10px;
	}
}
.link {
	color: $WHITE;
	font-family: $FONT_MAIN;
	line-height: 1;
	padding: 0;
	border: none;
	background-color: transparent;
	&:hover, &-active {
		color: $RED;
		user-select: none;
	}
	@media only screen and (max-width: 1199px) {
		font-size: 16px;
	}
}
.company {
	padding: 20px 0;
	line-height: 1.2;
	@media only screen and (max-width: 767px) {
		padding: 10px 0;
		font-size: 12px;
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.footer {
		padding-bottom: 50px;
	}
	.footer-row-left-text {
		display: block;
		width: 100%;
		text-align: center;
	}
	.phone {
		width: 100%;
		display: block;
		text-align: center;
		margin-left: 0;
		margin-top: 10px;
	}
}
