@import '~styles/colors';
@import '~styles/vars';

.error-block {
	padding: 50px 0;
}
.accent {
	font-size: 50px;
}
.description {
	font-size: 20px;
}
.button {
	margin-top: 40px;
}