@import '~styles/colors';

.categories-bar {
	position: relative;
	display: flex;
	padding: 8px 50px 8px;
	border-bottom: 1px solid $SEASHELL;
	background-color: $WHITE;
	width: 100%;
	scrollbar-color: $GRAY;
	scrollbar-width: thin;
	touch-action: manipulation;
	z-index: 9;

	&::-webkit-scrollbar {
		height: 5px;
		background-color: $WHITE;
		@media only screen and (max-width: 767px) {
			display: none;
			background-color: transparent;
		}
	}
	&::-webkit-scrollbar-thumb {
		background-color: $GRAY;
		border-radius: 3px;
		@media only screen and (max-width: 767px) {
			background-color: transparent;
		}
	}
	&--fixed {
		position: fixed;
		left: 0;
		top: -100%;
		visibility: hidden;
		opacity: 0;
		transition: all .65s ease-in-out;
		padding: 10px 40px 10px;
		box-shadow: 0 0 10px 3px rgba(0,0,0,.1);
	}

	&--showed {
		opacity: 1;
		visibility: visible;
		top: 85px;
		@media only screen and (max-width: 767px) {
			top: 65px;
		}
	}

	&--mobile {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
	}

	@media only screen and (max-width: 767px) {
		padding: 8px 0px;
	}

	&-owl-wrapper {
		position: relative;
	}

	&-owl-nav {
		line-height: 1;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		width: 100%;
		left: 0;
		display: flex;
		justify-content: space-between;
		pointer-events: none;
		button {
			pointer-events: auto;
			font-size: 22px;
			padding: 3px 10px 5px;
			border: 1px solid transparent;
			border-radius: 3px;
			&:hover {
				color: $RED;
				border: 1px solid $ALTO;
				background-color: darken($WHITE, 3%);
			}
			&:first-child {
				position: relative;
				left: -35px;
			}
			&:last-child {
				position: relative;
				right: -35px;
			}
		}
	}

	&-owl-stage {
		display: flex;
		align-items: center;
	}
}

.category-bar-item {
	width: 100%;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	touch-action: manipulation;

	&:hover &-name {
		color: $RED;
	}
	&:active {
		user-select: none;
	}
	@media only screen and (max-width: 991px) {
		font-size: 14px;
	}
	@media only screen and (max-width: 767px) {
		padding: 0 15px;
	}
	&-image {
		width: 65px;
		height: 65px;
		background: center/contain no-repeat;
		transition: all .1s ease-in-out;
		@media only screen and (max-width: 991px) {
			width: 45px;
			height: 45px;
		}
		&--hidden {
			display: none;
		}
		&--loading {
			opacity: 0.7;
			background-size: 3%;
			@media only screen and (max-width: 767px) {
				background-size: 5%;
			}
		}
	}
	&-name {
		margin-top: 5px;
		text-align: center;
		line-height: 1;
		touch-action: manipulation;

		&--fixed {
			margin-top: 0;
		}
		@media only screen and (max-width: 767px) {
			font-size: 14px;
		}
	}
}
