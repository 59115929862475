@import '~styles/vars';
@import '~styles/colors';


.conditions {
	padding-bottom: 40px;
}

.phone {
	display: block;
	color: $BLACK;
	margin: 15px 0;
	font-size: 30px;
}

.contact-block {
	background-color: $MERCURY;
	padding: 30px 0;
	&-image {
		background: center/contain no-repeat;
		height: 100%;
		min-height: 230px;
	}
	&-text {
		margin: 25px 0;
		font-size: 18px;
		line-height: 1.2;
	}
}

.condition {
	margin-bottom: 15px;
	padding: 0 20px;
	@media only screen and (max-width: 767px) {
		margin-bottom: 25px;
	}
	&-row {
		margin-bottom: 20px;
		&--accent {
			font-size: 22px;
			margin-top: 30px;
			font-family: $FONT_DEMI;
			line-height: 1.2;
			@media only screen and (max-width: 767px) {
				margin-bottom: 20px;
			}
		}
		&--description {
			font-size: 18px;
			line-height: 1.4;
			color: $MINE_SHAFT;
			font-family: $FONT_BOOK;
		}
		&--icon {
			width: 50px;
			height: 50px;
			background: center/contain no-repeat;
		}
	}
	.number {
		font-size: 36px;
		color: $RED;
		display: block;
		margin-bottom: 5px;
		&-description {
			color: $RED;
			display: block;
			font-size: 18px;
		}
	}
}
