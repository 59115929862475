.category {
	margin-top: 50px;
	margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
	.category {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	.product-row > [class*=col] {
		margin-top: -1px;
	}
}
