.wrapper {
	text-align: center;
	margin-top: 35px;
}

.attention-text {
}

.button {
	display: inline-block;
	margin-top: 35px;
}