$FONT_MAIN: 'Scada Regular', sans-serif;
$FONT_MAIN_OBLIQUE: 'Scada Italic', sans-serif;
$FONT_MAIN_BOLD: 'Scada Bold', sans-serif;
$FONT_BOOK: 'Scada Regular', sans-serif;
$FONT_DEMI: 'Scada Bold', sans-serif;
$FONT_AWESOME: FontAwesome;

$STANDART_BOX_SHADOW: 0px 0px 25px 5px rgba(0,0,0,.05);
$SALE_BOX_SHADOW: 0px 0px 25px 5px rgba(0,0,0,.1);
$STANDART_TRANSITION: all .15s ease-in-out;
