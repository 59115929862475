@import '~styles/colors';
@import '~styles/vars';

.labels {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding-top: 15px;
	&--left {
		text-align: left;
	}
	&--right {
		text-align: right;
	}
	@media only screen and (max-width: 767px) {
		padding-top: 0;
	}
}

.label {
	display: inline-block;
	font-size: 14px;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
	font-family: $FONT_MAIN_BOLD;
	&--left {
		margin-right: 15px;
	}
	&--right {
		margin-left: 15px;
	}
	&--popular {
		color: $RED;
	}
	&--new {
		color: $JAPANESE_LAUREL;
	}
}
