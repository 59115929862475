@import '~styles/colors';

.cart {
	&-table {
		padding: 0;
		margin-top: 30px;
	}
	&-row {
		transition: all .1s ease-in-out;
		&:hover {
			background-color: $HOVER_GRAY;
		}
	}
	&-article {
		display: block;
		color: $GRAY;
		font-size: 12px;
	}
	&-body {
		padding: 15px 0;
	}
}

.column {
	padding: 10px 0;
	font-size: 14px;
	&-gray {
		background-color: $ALABASTER;
		span {
			color: $GRAY;
		}
	}
	&-delete {
		text-align: center;
	}
	&-right {
		margin-left: 15px;
	}
	.delete-button {
		line-height: 1;
		display: inline-flex;
		align-items: center;
		text-align: center;
		touch-action: manipulation;
		&:hover {
			svg {
				fill: $RED;
			}
		}
		svg {
			fill: $BLACK;
			width: 13px;
		}
	}
}

.product {
	&-image {
		width: 70px;
		height: 70px;
		background: center/cover no-repeat;
		cursor: pointer;
		&--loading {
			opacity: 0.7;
			background-size: 3%;
			@media only screen and (max-width: 767px) {
				background-size: 5%;
			}
		}
	}
	&-id {
		color: $GRAY;
	}
	&-name {
		font-size: 16px;
		margin-top: 7px;
		cursor: pointer;
		&:hover {
			color: $RED;
		}
	}
	&-price, &-summary {
		font-size: 16px;
	}
	&-quantity {
		padding-left: 5px;
		font-size: 14px;
		color: $GRAY;
	}
}

@media only screen and (max-width: 767px) {
	.delete-button {
		padding: 10px;
	}
}
