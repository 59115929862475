@import '~styles/colors';
@import '~styles/vars';

.search {
	padding: 20px 0;
	&-input {
		width: 100%;
		input {
			display: inline-block;
			padding: 20px 30px;
			border: 1px solid $SILVER;
			transition: .1s ease-in-out;
			border-radius: 3px;
			outline: none;
			font-size: 20px;
			width: 100%;
			font-family: $FONT_MAIN;
			-webkit-appearance: none;
			-webkit-tap-highlight-color: transparent;
			&:focus {
				border-color: $DOVE_GRAY;
			}
			&::placeholder {
				color: $SILVER;
				opacity: 1;
			}
		}
	}
}
.search-note {
	padding: 10px 0;
	color: $GRAY;
}

@media only screen and (max-width: 767px) {
	.search {
		&-input {
			input {
				padding: 10px 10px;
				font-size: 16px;
			}
		}
	}
}
