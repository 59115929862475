@import '~styles/colors';
@import '~styles/vars';

.product-card-block {
	margin-top: 30px;
}
.image-block {
	position: relative;
}
.image {
	height: 400px;
	margin: 20px;
	background: center/contain no-repeat;
	&--loading {
		opacity: 0.7;
		background-size: 3%;
		@media only screen and (max-width: 767px) {
			background-size: 7%;
		}
	}
}
.row {
	margin-bottom: 25px;
}
.name {
	font-size: 20px;
	font-family: $FONT_DEMI;
	text-transform: uppercase;
}
.article {
	font-size: 14px;
	color: $GRAY;
}
.ingredients {
	padding: 10px 0;
	font-size: 16px;
	line-height: 1.2;
	font-family: $FONT_BOOK;
	&:first-letter {
		text-transform: capitalize;
	}
}
.quantity {
	font-family: $FONT_BOOK;
}
.price-block {
	margin-top: 50px;
	padding: 20px 0;
	border-top: 1px solid $ALTO;
	border-bottom: 1px solid $ALTO;
}
.price {
	font-size: 22px;
}
.product-actions {
	text-align: right;
	height: 38px;
}
.category {
	text-align: left;
	.category-name {
		border: 1px solid $ALTO;
		display: inline-block;
		padding: 7px 14px;
		border-radius: 3px;
		font-size: 16px;
		margin-bottom: 10px;
	}
}
.counter {
	border: none;
	input {
		font-size: 16px;
	}
}
.proposals {
	margin-top: 100px;
	padding: 30px 0;
}
.calories-table {
	margin-top: 80px;
}
@media only screen and (max-width: 767px) {
	.image {
		height: 300px;
		margin: 10px;
	}
	.proposals {
		margin-top: 35px;
		padding: 10px 0;
	}
}