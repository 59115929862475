@import '~styles/vars';
@import '~styles/colors';

.contacts {
	padding: 30px 0;
}

.phone {
	display: inline-block;
	padding-left: 20px;
	&--1 {
		padding-left: 0;
	}
}

.row {
	display: flex;
	margin-bottom: 20px;
	&-key {
		font-family: $FONT_MAIN_BOLD;
	}
	&-value {
		margin-left: 10px;
		a {
			color: $TEXT_COLOR;
			&:hover {
				color: $RED;
			}
		}
	}
}
