@import '~styles/colors';
@import '~styles/vars';

.wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: $MODAL_BACKGROUND;
	z-index: 99;
	opacity: 0;
	pointer-events: none;
	transition: .3s ease-in-out;
	will-change: left;
	-webkit-transform: translate3d(0,0,0);
	&--active {
		opacity: 1;
		pointer-events: all;
	}
}

.container {
	position: absolute;
	top: 0;
	left: -100%;
	height: 100%;
	transition: .3s ease-in-out;
	width: 80%;
	overflow-y: scroll;
	padding: 15px;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	background: $BLACK_GRADIENT;
	opacity: 0;
	will-change: left;
	-webkit-transform: translate3d(0,0,0);
	&--active {
		opacity: 1;
		left: 0;
	}
}
