@import '~styles/colors';
@import '~styles/vars';

.calories {
	overflow-x: auto;
	&-table {
		width: 100%;
		font-family: $FONT_BOOK;
		font-size: 14px;
		th {
			font-family: $FONT_DEMI;
			font-weight: normal;
			font-size: 15px;
			@media only screen and (max-width: 767px) {
				font-size: 14px;
			}
		}
	}
	&-row {
		border-top: 1px solid $ALTO;
		border-bottom: 1px solid $ALTO;
		text-align: left;
	}
	&-cell {
		padding: 12px 10px;
		@media only screen and (max-width: 767px) {
			padding: 8px 6px;
			font-size: 14px;
		}
	}
}
