@import '~styles/colors.scss';

.slick-dots {
	bottom: 3%;
}
.slick-dots li button::before {
	font-size: 0;
	display: inline-block;
	background-color: $WHITE;
	opacity: .25;
	border-radius: 100%;
	width: 50%;
	height: 50%;
}
.slick-dots li.slick-active button:before {
	opacity: .75;
}
.carousel-custom {
	line-height: 1;
	font-size: 0;
}
.carousel-item {
	outline: none;
}
@media only screen and (max-width: 767px) {
}