@import '~styles/colors';
@import '~styles/vars';

.navigation {
	padding: 0 25px;
	display: flex;
	justify-content: center;
	@media only screen and (max-width: 1199px) {
		padding: 0;
	}
	&-list {
		margin: 0;
		padding: 0;
		width: 100%;
		display: flex;
		align-items: center;
	}
	&-list-item {
		display: inline-block;
		margin: 0 8px;
		text-align: center;
		@media only screen and (max-width: 1199px) {
			text-align: center;
		}
		.navigation-link {
			color: $WHITE;
			font-family: $FONT_MAIN;
			padding: 10px 10px;
			border: 1px solid transparent;
			border-radius: 3px;
			line-height: 1;
			font-size: 18px;
			touch-action: auto;
			display: inline-block;
			width: 100%;
			&:hover, &-active {
				color: $RED;
				border-color: $MERCURY;
				background-color: darken($WHITE, 3%);
				user-select: none;
			}
			@media only screen and (max-width: 1199px) {
				font-size: 16px;
				padding: 10px 5px;
				&:hover, &-active {
					background-color: transparent;
					border-color: transparent;
				}
			}
		}
	}
	&--footer {
		text-align: left;
		justify-content: flex-start;
		padding: 0;
	}
	@media only screen and (max-width: 767px) {
		&--sidebar &-list {
			flex-direction: column;
			align-items: flex-start;
		}
		&--sidebar &-list-item {
			padding: 15px 0;
			width: 100%;
			text-align: left;
			margin: 0;
			.navigation-link {
				font-size: 18px;
			}
		}
	}
	&--footer &-list-item {
		text-align: left;
	}
	&--footer &-list {
		//justify-content: space-evenly;
	}
	&--footer &-list-item {
		display: inline-flex;
		margin-right: 35px;
		margin-left: 0;
		.navigation-link {
			color: $WHITE;
			font-family: $FONT_MAIN;
			line-height: 1;
			padding: 0;
			border: none;
			background-color: transparent;
			&:hover, &-active {
				color: $RED;
				user-select: none;
			}
			@media only screen and (max-width: 1199px) {
				font-size: 16px;
			}
		}
	}
}