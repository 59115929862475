@import '~styles/vars';

.sale {
	margin: 30px 0;
	transition: $STANDART_TRANSITION;
	&-image {
		background: center/contain no-repeat;
		min-height: 400px;
		border-radius: 3px;
		cursor: pointer;
		&--loading {
			opacity: 0.7;
			background-size: 5%;
			@media only screen and (max-width: 767px) {
				background-size: 10%;
			}
		}
		@media only screen and (max-width: 767px) {
			min-height: 120px;
		}
	}
	&-text {
		padding: 20px 0;
		user-select: none;
	}
	&-button {
		margin-bottom: 30px;
	}
}