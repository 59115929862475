@import '~styles/colors';
@import '~styles/vars';

.product {
	border: 1px solid transparent;
	transition: $STANDART_TRANSITION;
	cursor: pointer;
	display: inline-flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	-webkit-tap-highlight-color: transparent;

	&-top {
		position: relative;
	}

	&--default {
		padding: 0 15px 15px;
	}

	&:hover {
		box-shadow: $STANDART_BOX_SHADOW;
		border: 1px solid $BLACK;
	}
	&:focus {
		border: none;
		outline: none;
	}

	&-image {
		background: center/contain no-repeat;
		width: 100%;
		opacity: 1;
		&--loading {
			opacity: 0.7;
			background-size: 20%;
		}
		@media only screen and (max-width: 767px) {
			width: 40%;
			&--loading {
				background-size: 35%;
			}
		}
	}
	&-data {
		padding: 10px;
		width: 100%;
		&:hover .product-name {
			color: $RED;
		}
	}
	&-name {
		padding: 15px 0;
		text-align: center;
		font-size: 16px;
		font-family: $FONT_MAIN_BOLD;
	}
	&-ingredients {
		line-height: 1.2;
	}
	&-quantity {
		padding-left: 5px;
		font-size: 14px;
		color: $GRAY;
	}
	&-bottom {
		display: flex;
		margin-top: 15px;
		align-items: center;
		justify-content: space-between;
		//margin-bottom: 15px;
	}
	&-price {
		display: inline-flex;
		align-items: flex-end;
		width: 50%;
		text-align: left;
		span {
			display: inline-block;
		}
		.number {
			font-size: 18px;
		}
		.currency {
			margin-left: 5px;
			font-size: 14px;
		}
	}
	&-actions {
		width: 70%;
		text-align: right;
	}
	&--small {
		padding: 0 5px 5px;
		.product-bottom {
			flex-direction: column;
		}
		.product-price {
			width: 100%;
			justify-content: center;
		}
		.product-actions {
			width: 100%;
			margin-top: 10px;
			text-align: center;
			.add-button {
				width: 100%;
			}
		}
		&:hover {
			box-shadow: none;
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;
			border-top: 1px solid lighten($ALTO, 15%);
			border-bottom: 1px solid lighten($ALTO, 15%);
		}
	}
	&--small &-actions {
		.add-button {
			justify-content: center;
			border: 1px solid $ALTO;
			padding: 9px 14px;
		}
	}
}
.add-button {
	display: inline-flex;
	width: 80%;
	padding: 9px 0 9px 14px;
	text-align: center;
	justify-content: flex-end;
	color: $BLACK;
	font-family: $FONT_MAIN_BOLD;
	background-color: transparent;
	font-size: 14px;
	height: 38px;
	&:hover {
		color: $RED;
	}
	@media only screen and (max-width: 767px) {
		justify-content: flex-end;
	}
}

@media only screen and (max-width: 767px) {
	.product {
		border-top: 1px solid lighten($ALTO, 15%);
		border-bottom: 1px solid lighten($ALTO, 15%);
		padding: 20px 0 20px;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 0;

		&:hover {
			box-shadow: none;
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;
			border-top: 1px solid lighten($ALTO, 15%);
			border-bottom: 1px solid lighten($ALTO, 15%);
		}
		&-top {
			display: flex;
			align-items: flex-start;
			width: 100%;
		}
		&-image {
			width: 30%;
			display: inline-flex;
			position: relative;
		}
		&-data {
			padding: 0 0 0 35px;
			width: 70%;
			display: inline-flex;
		}
		&-name {
			text-align: left;
			padding: 8px 0;
		}
		&-bottom {
			margin-top: 15px;
			margin-bottom: 0;
		}
		&--small &-actions {
			margin-top: 25px;
			width: 35%;
			.add-button {
				justify-content: center;
				border: 1px solid $ALTO;
			}
		}
	}
}
