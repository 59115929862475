@import '~styles/vars';

.simple-modal {
	padding: 30px 30px;
	min-height: 250px;
	flex-direction: column;
	justify-content: space-between;
	display: flex;
	border-radius: 2px;
	box-shadow: 0 10px 40px 0 rgba(0,0,0,.15);
}

.header, .message {
	margin-bottom: 30px;
}

.header {
	font-size: 20px;
}

.message {
	font-size: 16px;
	line-height: 1.4;
	white-space: pre-line;
}

.action-buttons {
	display: flex;
	justify-content: flex-end;
}

.action-button {
	margin-left: 5px;
}
