@import './colors';
@import './vars';

body {
	padding-top: 85px;
	font-family: $FONT_MAIN;
	font-size: 14px;
	line-height: 1.4;
	background-color: $WHITE;
	color: $TEXT_COLOR;
	min-width: 320px;
	overflow-x: hidden;
	touch-action: manipulation;
	-webkit-tap-highlight-color: transparent;

	@media only screen and (max-width: 767px) {
		padding-top: 65px;
	}
}

a, button {
	-webkit-tap-highlight-color: transparent;
	font-family: $FONT_MAIN;
}

input {
	font-family: $FONT_MAIN;
}

button::-moz-focus-inner, input::-moz-focus-inner, select::-moz-focus-inner {
	border: 0;
}

#page {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

.wysiwyg-data {
	font-family: $FONT_BOOK;
}

strong, b {
	font-family: $FONT_MAIN_BOLD;
}