.summary {
	padding: 20px 0;
}
.actions {
	margin-top: 20px;
}
.summary-number {
	display: inline-block;
	margin-left: 5px;
	font-weight: 700;
}
.remember-items {
	padding: 20px 0;
	@media only screen and (max-width: 767px) {
		margin-top: 35px;
	}
}