@import '~styles/colors.scss';

.counter {
	display: inline-flex;
	align-items: center;
	background-color: $WHITE;
	border-radius: 3px;
	font-size: 0;
	//border: 1px solid $ALTO;
	height: 38px;

	&-value {
		border: none;
		outline: none;
		background-color: transparent;
		font-size: 16px;
		color: $BLACK;
		width: 30%;
		text-align: center;
		touch-action: manipulation;
		min-width: 30px;
		@media only screen and (max-width: 767px) {
			font-size: 14px;
		}
	}

	button {
		font-size: 0;
		padding: 5px 0;
		width: 35%;
		display: inline-block;
		touch-action: manipulation;
		svg {
			width: 10px;
			fill: $BLACK;
		}
		&:hover {
			svg {
				fill: $RED;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.counter {
		&-value {
			pointer-events: none;
		}
	}
}
