@import './colors';

[class*="col-"] {
	line-height: 1;
}

.wysiwyg-data {
	a {
		color: $BLACK;
		text-decoration: underline;
		&:hover {
			color: $RED;
		}
	}
}