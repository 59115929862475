@import '~styles/colors';

.head {
	background-color: $GALLERY;
	padding: 15px 0;
}
.body {
	padding: 15px 0;
	line-height: 1;
}
.row {
	transition: .15s ease-in-out;
	&:hover {
		background-color: $GALLERY;
	}
}