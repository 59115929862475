@import '~styles/colors';

.checkbox-wrapper {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

.checkbox {
	margin: 3px;
	background-color: $WHITE;
	border-radius: 2px;
	width: 16px;
	height: 16px;
	border: 1px solid $ALTO;
	cursor: pointer;
	position: relative;
	//transition: border .2s ease;
	.icon {
		width: 100%;
		height: 100%;
		opacity: 0;
		position: relative;
		transition: .1s ease-in-out;
	}
	&-checked {
		border: 1px solid $BLACK_LIGHT;
		.icon {
			opacity: 1;
		}
	}
	svg {
		width: 100%;
		height: 100%;
		fill: $BLACK_LIGHT;
		position: absolute;
		top: 0;
		left: 0;
		padding: 2px;
	}
}
.label {
	margin-left: 5px;
	user-select: none;
}
