@import '~styles/colors';

.cart {
	display: inline-flex;
	align-items: center;
	padding: 10px 20px;
	border: 1px solid transparent;
	border-radius: 3px;
	-webkit-tap-highlight-color: transparent;
	touch-action: manipulation;

	@media only screen and (max-width: 1199px) {
		padding: 5px 5px;
	}
	@media only screen and (max-width: 767px) {
		font-size: 14px;
		svg {
			width: 15px;
		}
	}
	.cart-count {
		margin-left: 5px;
		font-size: 16px;
		@media only screen and (max-width: 767px) {
			font-size: 14px;
		}
	}
	.cart-text {
		margin-left: 10px;
	}
	svg {
		fill: $WHITE;
		width: 20px;
	}
	&:hover, &--active {
		cursor: pointer;
		border-color: $MERCURY;
		color: $RED;
		background-color: darken($WHITE, 3%);
		svg {
			fill: $RED;
		}
	}
	&:active {
		user-select: none;
	}
	&--sidebar {
		padding: 0;
		font-size: 18px;
		margin-top: 20px;
	}
	&--sidebar {
		background-color: transparent;
		border-color: transparent;
		color: $WHITE;
		svg {
			fill: $WHITE;
		}
		&:hover {
			background-color: transparent;
			border-color: transparent;
			svg {
				fill: $RED;
			}
		}
	}
}
