@import '~styles/colors';
@import '~styles/vars';

.heading {
	font-size: 30px;
	color: $DOVE_GRAY;
	font-family: $FONT_MAIN_BOLD;
	padding: 15px 0;

	&-size-1 {
		font-size: 65px;
	}
	&-size-2 {
		font-size: 50px;
	}
	&-size-3 {
		font-size: 40px;
	}
	&-size-4 {
		font-size: 32px;
	}
	&-size-5 {
		font-size: 26px;
	}
	&-size-6 {
		font-size: 20px;
	}
}

@media only screen and (max-width: 1199px) {
	.heading{
		&-size-1 {
			font-size: 50px;
		}
		&-size-2 {
			font-size: 42px;
		}
		&-size-3 {
			font-size: 34px;
		}
		&-size-4 {
			font-size: 26px;
		}
		&-size-5 {
			font-size: 20px;
		}
		&-size-6 {
			font-size: 14px;
		}
	}
}

@media only screen and (max-width: 767px) {
	.heading{
		&-size-1 {
			font-size: 46px;
		}
		&-size-2 {
			font-size: 38px;
		}
		&-size-3 {
			font-size: 28px;
		}
		&-size-4 {
			font-size: 24px;
		}
		&-size-5 {
			font-size: 18px;
		}
		&-size-6 {
			font-size: 14px;
		}
	}
}