@import '~styles/colors';

.header {
	padding: 21px 0;
	position: fixed;
	height: 85px;
	z-index: 10;
	transition: all .05s ease-in-out;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	color: $WHITE;
	background: linear-gradient($TUNDORA, $BLACK);
	&--fixed {
		padding: 10px 0;
	}
	@media only screen and (max-width: 767px) {
		height: 65px;
	}
}