@import '~styles/colors';

.wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	padding: 20px;
	top: 0;
	left: 0;
	background-color: $MODAL_BACKGROUND;
	z-index: 99;
	display: none;
	overflow-y: auto;
	&--active {
		display: block;
	}
}

.container {
	min-height: 100%;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 20px 0; // Из-за бага в мозилле
}

.modal {
	border: 1px solid $ALTO;
	background-color: $WHITE;
	position: relative;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	outline: none;

	&-tiny {
		width: 500px;
	}

	&-giant {
		width: 75vw;
	}

	.close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		opacity: 0.65;
		color: $BLACK;
		font-style: normal;
		font-size: 28px;
		cursor: pointer;
		user-select: none;
		display: flex;
		align-items: center;
		justify-content: center;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			opacity: 1;
		}
	}
}

@media only screen and (max-width: 767px) {
	.modal {
		&-tiny, &-giant {
			width: 95vw;
		}
	}
}
