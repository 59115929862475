@import '~styles/colors';
@import '~styles/vars';

.row {
	margin-bottom: 25px;
}
.actions {
	margin-top: 35px;
	display: flex;
	justify-content: space-between;
}
.address {
	margin-top: 10px;
	font-family: $FONT_MAIN_BOLD;
}
.errors, .warnings {
	margin: 20px 0;
}
.error, .warning {
	text-align: right;
	font-size: 16px;
	margin: 5px 0;
}
.error {
	color: $RED;
}
.warning {
	color: $WARNING;
}
.policy-block {
	a {
		margin: 0 5px 0;
		color: $BLACK;
		text-decoration: underline!important;
		&:hover {
			color: $RED;
		}
	}
}
