@import '~styles/colors';

.search {
	display: inline-flex;
	align-items: center;
	padding: 10px 20px;
	border: 1px solid transparent;
	border-radius: 3px;
	-webkit-tap-highlight-color: transparent;
	touch-action: manipulation;

	.search-text {
		margin-left: 5px;
		font-size: 16px;
		color: $WHITE;
		@media only screen and (max-width: 767px) {
			font-size: 18px;
			&:hover {
				color: $RED;
			}
		}
	}
	svg {
		fill: $WHITE;
		width: 20px;
	}
	&:hover, &--active {
		cursor: pointer;
		border-color: $MERCURY;
		color: $RED;
		background-color: darken($WHITE, 3%);
		svg {
			fill: $RED;
		}
		.search-text {
			color: $RED;
		}
	}
	&:active {
		user-select: none;
	}
	@media only screen and (max-width: 1199px) {
		padding: 6px;
		font-size: 15px;
		svg {
			width: 15px;
		}
	}
	@media only screen and (max-width: 767px) {
		padding: 0;
		border: none;
		font-size: 18px;
		svg	{
			width: 20px;
		}
		.search-text {
			margin-left: 10px;
		}
		&:hover, &--active {
			border-color: transparent;
			background-color: transparent;
			svg {
				fill: $RED;
			}
		}
	}
}
